import styled, { keyframes } from 'styled-components';
import { sidebarWidth } from '../../../../../../globals';

// const opacityAnim = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    height: calc(100vw*9/16);
    width: 100vw;
    background-image: linear-gradient(to right, #2b2425 0%, #352e2f 100%);
    background-color: rgba(250, 242, 225, 0.7);
    opacity: 1;
    transition: 1s;
`;

export const FullContainer = styled.div `
  width: 100vw;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(250, 242, 225, 1);
`;

export const InitialViewContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transition: 0.8s;
  ${({ showView }) => showView && `
    top: -100vh;
  `}
`;

