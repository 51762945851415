import styled from 'styled-components';

export const BulletsContainer = styled.div`
  width: 47vw;
  height: 54vh; 
  display: flex;
  margin-left: 4vw;
  position: relative;
  flex-direction: column;
`;

export const SingleBullet = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
`
export const BulletsTextContainer = styled.div`
  width: 100%;
  display: flex;
`
export const BulletTextContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 10%;
  padding-top: 20%;
  h4 {
    font-family: "Red Hat Display";
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #484440;
  }
  p {
    font-family: "Red Hat Display";
    color: #484440;
  }
`

export const BackLine = styled.div`
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  background-color: rgba(72, 68, 64, 0.1);
  opacity: 0.4;
`