import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch } from 'react-redux';
import { Wrapper, Dot, DotHover, MapTag } from './styles';
import actions from 'src/store/actions';

import Initial from './animationFiles/initial';
import Ciemnozielone from './animationFiles/ciemnozielone';
import Czerwony from './animationFiles/czerwony';
import Niebieski from './animationFiles/niebieski';
import Pomaranczowy from './animationFiles/pomaranczowy';
import Rozowy from './animationFiles/rozowy';
import Zielony from './animationFiles/zielony';
import Zolty from './animationFiles/zolty';

const Map = ({ dotArray, modalInfo, resetAnimation }) => {
    const assetQuery = useStaticQuery(graphql`
        query {
            mainscene: file(relativePath: { eq: "chapter_4/sub_7/desktop/bg_map.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1920)  {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
        `);
    const [animationindex, setAnimationIndex] = useState(0);
    const dispatch = useDispatch();
    const dispatchModal = (modalInfo, index, isPoland) => {
        if (parseInt(index) !== 0 || parseInt(index) === 0 && !JSON.parse(isPoland)) {
            dispatch({ type: actions.SET_SHOW_ARMY_MODAL, payload: true });
            dispatch({ type: actions.SET_ARMY_MODAL_CONTENTS, payload: modalInfo });
            setAnimationIndex(parseInt(index));
        }
    };

    const handleExit = (e) => {
        if (e.keyCode === 39) {
            dispatch({ type: actions.SET_SHOW_ARMY_MODAL, payload: false });
            dispatch({ type: actions.CLEAR_ARMY_MODAL });
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleExit);

        return () => {
            dispatch({ type: actions.SET_SHOW_ARMY_MODAL, payload: false });
            dispatch({ type: actions.CLEAR_ARMY_MODAL });
            document.removeEventListener("keydown", handleExit);
        }
    }, [])

    const allAnimations = [<Initial />, <Ciemnozielone />, <Czerwony />, <Niebieski />, <Pomaranczowy />, <Rozowy />, <Zielony />, <Zolty />]
    const allDots = dotArray.map((dot, i) => {
        const style1 = {
            left: dot.left,
            top: dot.top,
            backgroundColor: dot.backgroundColor
        }
        const style2 = {
            left: dot.left,
            top: dot.top,
            backgroundColor: dot.backgroundColor,
            width: '0.5rem',
            height: '0.5rem',
        }
        return (<Dot key={dot.index + i} onClick={() => { dispatchModal(modalInfo[i], dot.index, dot.isPolish) }} style={JSON.parse(dot.isPolish) ? style2 : style1}>
            {JSON.parse(dot.isPolish) && (
                <MapTag>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#444239" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" /></svg>
                </MapTag>
            )}
            <DotHover closerText={JSON.parse(dot.isPolish)} showText={parseInt(dot.index) === animationindex && resetAnimation || resetAnimation && animationindex !== 0 && JSON.parse(dot.isPolish)}>{dot.insideText}</DotHover>
        </Dot>)
    })
    return (
        <Wrapper>
            <Img fluid={assetQuery.mainscene.childImageSharp.fluid} style={{ width: '100%', height: '100%' }} />
            {resetAnimation && (allAnimations[animationindex])}
            {allDots}
        </Wrapper>
    )
};

export default Map;
