import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'src/store/actions';

import { Wrapper, FullContainer, InitialViewContainer } from './styles/index.js';
import Map from '../map/map';
import InitialView from '../initialView/initialView';
import { motion, AnimatePresence } from "framer-motion"


const MainWrapper = ({  assets, initialScreen, dotArray, modalInfo }) => {
    const [initialScreenVisible, setInitialScreenVisible] = useState(false);
    const show = useSelector((state) => state.showArmyModal);
    
    const hideInitialScreen = () => {
        setInitialScreenVisible(true)
    }

    return (
        <>
            <FullContainer>
                <InitialViewContainer showView={initialScreenVisible}>
                    <InitialView data={initialScreen} assets={assets} hideInitialScreen={hideInitialScreen} />
                </InitialViewContainer>
                <Wrapper style={{transform: show ? 'translate3d(-20vw, 0, 0)' : 'translate3d(-5vw, 0, 0)'}}>
                    <Map dotArray={dotArray} modalInfo={modalInfo} resetAnimation={show}/>
                </Wrapper>
            </FullContainer>
        </>
    )
};

export default MainWrapper;
