import React from 'react';
import { AnimationWrapper} from './styles';

const Map = () => {

    return (
        <AnimationWrapper>

        </AnimationWrapper>
    )
};

export default Map;