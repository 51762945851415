import styled, { keyframes } from 'styled-components';
import { fonts } from '../../../../../../globals';

const arrowAnimation = keyframes`
  from {
    transform: translate3d(-.4rem, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0.4rem, 0, 0);
    opacity: 0;
  }
`;
 
const slideUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0,70px,0);
}
to {
  opacity: 1;
  transform: translate3d(0,0,0);
}
`;

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 5vw;
    color: #645d5e;        
    transition: opacity 2s;


    h2 {
        ${{ ...fonts.headline_1 }}
        text-align: center;
        animation: ${slideUp} 1s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards;
        color: #272323;
    }

    p, span {
        ${{ ...fonts.paragraf }}
        width: 30rem;
        text-align: center;
        animation: ${slideUp} 1.4s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards;
        color: #272323;
    }
`;


export const Button = styled.div`
    position: relative;
    display: inline-block;
    font-weight: 500;
    padding: 1.3rem 6rem 1.3rem 6rem;
    margin: 0;
    border: 1px solid #645d5e;
    margin: 1vh 1.3rem 0 0;
    &:hover {
        cursor: pointer;
        background: #645d5e;
        color: #f9f8f3;
    }
`;

export const ArrowContainer = styled.div`
    position: absolute;
    right: -1.3rem;
    top: 50%;
    transform: translateY(-50%);
    width: 2.6rem;
    height: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #df3242;
`;

export const Arrow = styled.img`
    animation: ${arrowAnimation} 1.4s ease-in-out infinite forwards;
`;

export const MainBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(250, 242, 225, 0.7);
`