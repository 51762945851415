import React, { useState } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';
import {
  BulletsContainer, SingleBullet, BulletTextContainer, BackLine, BulletsTextContainer
} from './styles';


const Bullet = ({
  bulletTexts, bulletPhoto
}) => {
  return (
    <>
      <BulletsContainer>
          <BackLine style={{top: '20%'}}></BackLine> 
          <BackLine style={{top: '40%'}}></BackLine>
          <BackLine style={{top: '60%'}}></BackLine>
        <Img fluid={bulletPhoto}></Img>
        <BulletsTextContainer>
          <SingleBullet>
            <BulletTextContainer>
              <h4>{bulletTexts.exports.bulletsInfo[0].type}</h4>
              <p>{bulletTexts.exports.bulletsInfo[0].caliber}</p>
            </BulletTextContainer>
          </SingleBullet>
          <SingleBullet>
            <BulletTextContainer>
              <h4>{bulletTexts.exports.bulletsInfo[1].type}</h4>
              <p>{bulletTexts.exports.bulletsInfo[1].caliber}</p>
            </BulletTextContainer>
          </SingleBullet>
          <SingleBullet>
            <BulletTextContainer>
              <h4>{bulletTexts.exports.bulletsInfo[2].type}</h4>
              <p>{bulletTexts.exports.bulletsInfo[2].caliber}</p>
            </BulletTextContainer>
          </SingleBullet>
          <SingleBullet>
            <BulletTextContainer>
              <h4>{bulletTexts.exports.bulletsInfo[3].type}</h4>
              <p>{bulletTexts.exports.bulletsInfo[3].caliber}</p>
            </BulletTextContainer>
          </SingleBullet>
          <SingleBullet>
            <BulletTextContainer>
              <h4>{bulletTexts.exports.bulletsInfo[4].type}</h4>
              <p>{bulletTexts.exports.bulletsInfo[4].caliber}</p>
            </BulletTextContainer>
          </SingleBullet>
        </BulletsTextContainer>
      </BulletsContainer>
    </>
  );
}

export default Bullet;
