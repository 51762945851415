import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Wrapper, MainBackground, Button, ArrowContainer, Arrow } from './styles';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import arrowImg from 'src/assets/images/_shared/button-arrow.svg';

const InitialView = ({ data, hideInitialScreen }) => {

    return (

        <ParallaxMordo additionalStyling={{ overflow: 'visible' }}>

            <MainBackground>

                    <Wrapper>
                        <MDXProvider components={{
                            Button,
                            ArrowContainer,
                            Arrow
                        }}>
                            <MDXRenderer arrow={arrowImg} handleHide={hideInitialScreen}>
                                {data}
                            </MDXRenderer>
                        </MDXProvider>
                    </Wrapper>

            </MainBackground>
        </ParallaxMordo>

    )
};

export default React.memo(InitialView);
