import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

// Dodane testowo slajdy z rozdziału pierwszego!!!
// TODO: zmienić na właściwe slajdy
import Slide1 from 'src/slides/desktop/hu/chapter_4/sub_7/slide1';
import Slide2 from 'src/slides/desktop/hu/chapter_4/sub_7/slide2';

import WrapperMobile from 'src/slides/mobile/hu/chapter_4/sub_7/wrapperMobile';
import { isMobile } from 'react-device-detect';
// import Slide
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Mobile = () => (
  <>
    <SEO title="Lengyel Hadsereg 1920 | A varsói csata" lang="hu" description="Önkéntesek, martalócok és veteránok. Ők tartóztatták fel a bolsevikokat." />
    <WrapperMobile />
  </>
);

const Desktop = () => (
  <>
    <SEO title="Lengyel Hadsereg 1920 | A varsói csata" lang="hu" description="Önkéntesek, martalócok és veteránok. Ők tartóztatták fel a bolsevikokat." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
