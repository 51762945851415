import React, { createRef, useEffect } from 'react';
import { AnimationWrapper, MainAnimation } from './styles';
import lottie from 'lottie-web';
import animation from '../animations/zielony_usa2.json';

const Map = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animation,
        });
        setTimeout(() => { anim.play(); }, 1000);

        return () => anim.destroy();
    }, []);
    return (
        <AnimationWrapper>
            <MainAnimation ref={animationContainer} right={0.5} top={0.5} />
        </AnimationWrapper>
    )
};

export default Map;