import styled, { keyframes, css } from 'styled-components';
import { fonts, colors } from '../../../../../../globals';


export const Wrapper = styled.div `
  width: 100%;
  height: 100%;
  background-color: #fff; 
  display: flex;
  position: relative;
`;

export const DotHover = styled.div`
  background-color: #444239;
  padding: 0.25rem 0.8rem;
  font-family: "Red Hat Display";
  font-size: 0.85rem;
  color: #f8f0f1;
  position: absolute;
  display: flex;
  white-space: nowrap;
  right: 3rem;
  transition: 0.3s;
  display: none;
  font-weight: 500;
  ${({ closerText }) => closerText && `
    right: 1.5rem;
    top: -0.5rem;
  `}
  ${({showText}) => showText && `
    display: block;
  `}
  &:after {
    content: '';
    position: absolute;
    right: -0.46rem;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.8rem 0 0.8rem 0.5rem;
    border-color: transparent transparent transparent #444239;
  }
`

export const Dot = styled.div`
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.23);
  &:hover ${DotHover}{
    display: block;
  }
`;

export const MapTag = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: -2.5rem;
  left: -0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 2rem;
    height: 2rem;
  }
`