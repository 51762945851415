import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import Bullets from 'src/components/desktop/bullets/bullets';
import { SlideLarge } from '../../_styles';

const Slide = ({ query }) => {
  const images = useStaticQuery(graphql`
      query {
        bulletPhoto: file(relativePath: { eq: "chapter_4/sub_7/desktop/bullet.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bg: file(relativePath: { eq: "chapter_4/sub_7/desktop/bg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      `);
  return (
    <SlideLarge style={{backgroundImage: `url(${images.bg.childImageSharp.fluid.src})`, display: 'flex', alignItems: 'center'}}>
      <LeftColContent body={query.leftText.body} dark smallerTitle style={{marginLeft: '7vw'}}/>
      <Bullets bulletTexts={query.bulletTexts} bulletPhoto={images.bulletPhoto.childImageSharp.fluid}></Bullets>
    </SlideLarge>

  );
};


export default Slide;
