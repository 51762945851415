import styled, { keyframes, css } from 'styled-components';
import { fonts, colors } from '../../../../../../globals';

export const AnimationWrapper = styled.div`
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`

export const MainAnimation = styled.div`
    width: 100%;
    height: 100%;
`