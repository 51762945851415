import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MapSlider from '../../../../../components/desktop/armyMaking/mainWrapper/mainWrapper';
import { SlideLarge } from '../../_styles';

const Slide = ({
  query, modals, quote, titles, initialScreen, dotArray, modalInfo,
}) => {
  const assetQuery = useStaticQuery(graphql`
    query {
    audioImage_1: file(relativePath: { eq: "_shared/slider_kopanski.png"}) {
        childImageSharp {
            fluid(maxWidth: 275) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
    },
    audioImage_2: file(relativePath: { eq: "_shared/slider_osmecki.png"}) {
        childImageSharp {
            fluid(maxWidth: 275) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
    },
    audioImage_3: file(relativePath: { eq: "_shared/slider_jedrzejewicz.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  audioImage_4: file(relativePath: { eq: "_shared/slider_krzeczunowski.png"}) {
    childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
},
  modalImage1: file(relativePath: { eq: "chapter_3/sub_5/3-5-9-modal.png"}) {
    childImageSharp {
      fixed(width: 669, height: 712)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  img_3_5_2: file(relativePath: { eq: "chapter_3/sub_5/3-5-2.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  img_3_5_3: file(relativePath: { eq: "chapter_3/sub_5/3-5-3.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  img_3_5_8: file(relativePath: { eq: "chapter_3/sub_5/3-5-8.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  img_3_5_9: file(relativePath: { eq: "chapter_3/sub_5/3-5-9.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  img_3_5_13: file(relativePath: { eq: "chapter_3/sub_5/3-5-13.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  img_3_5_14: file(relativePath: { eq: "chapter_3/sub_5/3-5-14.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  img_3_5_15: file(relativePath: { eq: "chapter_3/sub_5/3-5-15.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  img_3_5_17: file(relativePath: { eq: "chapter_3/sub_5/3-5-17.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  paralax_back: file(relativePath: { eq: "chapter_3/sub_5/paralax/paralax-back.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  paralax_front: file(relativePath: { eq: "chapter_3/sub_5/paralax/paralax-front.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  img_3_5: file(relativePath: { eq: "chapter_3/sub_5/3-5.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  }
  `);
  return (
    <SlideLarge>
      <MapSlider
        dotArray={dotArray}
        data={query}
        assets={assetQuery}
        modals={modals}
        quote={quote}
        titles={titles}
        initialScreen={initialScreen}
        modalInfo={modalInfo}
      />
    </SlideLarge>
  );
};

export default Slide;
